import React, { useEffect, useRef, useState } from 'react';
import { TabsProps } from '../../types/tabs.types';
import { FMCTabs } from '@ford/ford-design-system';
import './../../main.scss';

const Tabs = (props: TabsProps) => {
    const [selectedIndex, setSelectedIndex] = useState(
        props.activeTabIndex || 0
    );
    const tabsRef = useRef();
    const selectedTabPanelRef = useRef();
    useEffect(() => {
        try {
            new FMCTabs(tabsRef.current);
            if (selectedTabPanelRef.current) {
                const selectedTabPanel =
                    selectedTabPanelRef.current as HTMLDivElement;
                if (
                    !selectedTabPanel.classList.contains(
                        'fmc-tabs__tabpanel--active'
                    )
                )
                    selectedTabPanel.classList.add(
                        'fmc-tabs__tabpanel--active'
                    );
            }
        } catch (error) {
            console.error('Error initializing FDS Tabs ', error.message);
        }
    }, [props.activeTabIndex]);

    const handleTabClick = (tabIndex: number) => {
        if (props.onChange) {
            props.onChange(tabIndex);
        }
        setSelectedIndex(tabIndex);
    };

    const tabs = () => {
        return props.tabs.map((tab, index) => (
            <button
                key={index}
                className={`fmc-tabs__tab ${
                    selectedIndex === index ? `fmc-tabs__tab--active` : ''
                } ${props.className || ''}`}
                role='tab'
                aria-selected={selectedIndex === index}
                aria-controls={`TabsPanel-${index}`}
                id={`TabsButton-${index}`}
                tabIndex={selectedIndex === index ? 0 : -1}
                onClick={() => {
                    handleTabClick(index);
                }}
            >
                {tab.title}
            </button>
        ));
    };

    const tabPanels = () => {
        return props.tabs.map((tab, index) => (
            <div
                {...(selectedIndex === index
                    ? { ref: selectedTabPanelRef }
                    : {})}
                key={index}
                id={`TabsPanel-${index}`}
                className={`fmc-tabs__tabpanel ${
                    selectedIndex === index ? 'fmc-tabs__tabpanel--active' : ''
                }`}
                role='tabpanel'
                aria-labelledby={`TabsPanel-${index}`}
                tabIndex={selectedIndex === index ? 0 : -1}
            >
                {tab.children}
            </div>
        ));
    };

    return (
        <div
            ref={tabsRef}
            className={`js-fmc-tabs fmc-tabs ${props.className || ''}`}
        >
            <div className='fmc-tabs__tablist-wrapper'>
                <div
                    className='fmc-tabs__tablist'
                    role='tablist'
                    aria-label='Features'
                >
                    {tabs()}
                    <span className='fmc-tabs__tab-border' />
                </div>
            </div>
            <div className='fmc-tabs__tabpanels fds-color--gray3'>
                {tabPanels()}
            </div>
        </div>
    );
};
export default Tabs;
