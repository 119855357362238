import React from 'react';
import { InputProps } from '../../types/Input.types';
import './../../main.scss';

const Input = React.forwardRef(function Input(
    props: InputProps,
    ref: React.Ref<HTMLInputElement>
) {
    const inputClasses = () => {
        const classes: string[] = ['fmc-input'];
        if (props.state) {
            classes.push(`fmc-input--${props.state}`);
        }
        if (props.customclass) {
            classes.push(props.customclass);
        }
        return classes.join(' ').trim();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };
    return (
        <input
            id={props.id}
            value={props.value}
            className={inputClasses()}
            {...(props.disabled ? { disabled: true } : {})}
            {...(props.required ? { required: true } : {})}
            {...(props.minLength ? { minLength: props.minLength } : {})}
            {...(props.maxLength ? { maxLength: props.maxLength } : {})}
            {...(props.readOnly ? { readOnly: props.readOnly } : {})}
            data-testid={props.dataTestId}
            onChange={(e) => onChange(e)}
            onClick={props.onClick}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyDown={props.onKeyDown}
            onKeyUp={props.onKeyUp}
            placeholder={props.placeholder}
            type={props.type || 'text'}
            name={props.name}
            aria-labelledby={props.ariaLabelledBy}
            ref={ref}
            aria-describedby={props.ariaDescribedBy}
            aria-required={props.ariaRequired}
            aria-label={props.ariaLabel}
        />
    );
});
export default Input;
