import React from 'react';
import './fm-list-item.scss';
import { FMListItemProps } from '../../types/fm-list-item.types';

import { Chevron } from '../../index';
const FMListItem = (props: FMListItemProps) => {
    const onClick = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (props.onClick) {
            props.preventDefault && e.preventDefault();
            props.onClick(e);
        }
    };
    const listItemClasses = () => {
        const classes: string[] = ['fm-list-item-container'];
        if (props.type) {
            classes.push(`fm-list-item-container--${props.type}`);
        }
        return classes.join(' ').trim();
    };

    const sanitizeTitle = () => {
        return props?.title?.toLowerCase()?.split(' ')?.join('-')?.trim();
    };

    return (
        <a
            href={props.ctaPath}
            className={listItemClasses()}
            data-testid={`list-item-${sanitizeTitle()}`}
            onClick={(e) => onClick(e)}
        >
            {props.imgPath && props.type !== 'no-icon' && (
                <img
                    className={'fm-list-item__icon'}
                    src={props.imgPath}
                    alt={''}
                />
            )}
            <div className={'fm-list-item-text__container'}>
                <div className={'fm-list-item__title'} aria-label={props.title}>
                    {props.title}
                </div>
                {props.description && props.type !== 'no-description' && (
                    <div
                        className={'fm-list-item__description'}
                        aria-label={props.description}
                    >
                        {props.description}
                    </div>
                )}
            </div>
            {props.showChevron && (
                <div className={'fm-list-item__chevron'}>
                    <Chevron direction={'right'} />
                </div>
            )}
        </a>
    );
};
export default FMListItem;
