/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useEffect } from 'react';
import { PaginationProps } from '../../types/pagination.types';
import './../../main.scss';

const Pagination = (props: PaginationProps) => {
    let currentPage = props.currentPage;
    let currentData = props.dataItems;
    const maxPaginationNumber = Math.ceil(
        props.dataItems.length / props.itemsPerPage
    );
    const pageNumbersBetweenEllipses = 4;
    const pageNumbersTouchingEllipses = 2;
    const minPageNumbersToSkipBeforeEllipses = 3;
    const minPageNumbersToSkipAfterEllipses = 5;
    const firstPage = 1;
    const data = () => {
        const begin = (currentPage - 1) * props.itemsPerPage;
        const end = begin + props.itemsPerPage;
        const processedData = props.dataItems
            .slice(begin, end)
            .map((item: ReactNode) => item);
        currentData = processedData;
    };

    const updateCurrentPage = (pageNumber: number) => {
        currentPage = pageNumber;
        props?.updateCurrentPageNumber &&
            props.updateCurrentPageNumber(currentPage);
        data();
        if (!props.isNoScroll) {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        if (currentPage > maxPaginationNumber) {
            props?.updateCurrentPageNumber &&
                props.updateCurrentPageNumber(maxPaginationNumber);
            data();
        }
    }, [currentData, currentPage, maxPaginationNumber]);

    const rangeItems = (start: number, stop: number) => {
        return [...Array.from(Array(stop - start + 1).keys())].map((value) => {
            const ariaCurrent =
                currentPage === start + value ? 'page' : 'false';

            return (
                <li className='fmc-pagination__item' key={start + value}>
                    <a
                        className={`fmc-pagination__link ${
                            currentPage === start + value
                                ? 'fmc-pagination__active'
                                : ''
                        }`}
                        href='#'
                        role='button'
                        aria-current={ariaCurrent}
                        title={`${props.pageNumberTitle} ${start + value}`}
                        onClick={(
                            event: React.MouseEvent<HTMLAnchorElement>
                        ) => {
                            event.preventDefault();
                            updateCurrentPage(start + value);
                        }}
                    >
                        <span aria-hidden='true'>{start + value}</span>
                    </a>
                </li>
            );
        });
    };
    const beforeAriaCurrent = currentPage === 1 ? 'page' : 'false';
    const beforeEllipses = (
        <>
            <li className='fmc-pagination__item' key={1}>
                <a
                    className={`fmc-pagination__link ${
                        currentPage === 1 ? 'fmc-pagination__active' : ''
                    }`}
                    href='#'
                    role='button'
                    aria-current={beforeAriaCurrent}
                    title={`${props.pageNumberTitle} ${1}`}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        event.preventDefault();
                        updateCurrentPage(1);
                    }}
                >
                    <span aria-hidden='true'>{1}</span>
                </a>
            </li>
            <li
                key={-1}
                className='fmc-pagination__item fmc-pagination__link fmc-pagination__disabled'
                aria-label={props.morePagesAriaLabel}
            >
                &hellip;
            </li>
        </>
    );
    const afterAriaCurrent =
        currentPage === maxPaginationNumber ? 'page' : 'false';
    const afterEllipses = (
        <>
            <li
                key={-2}
                className='fmc-pagination__item fmc-pagination__link fmc-pagination__disabled'
                aria-label={props.morePagesAriaLabel}
            >
                &hellip;
            </li>
            <li className='fmc-pagination__item' key={maxPaginationNumber}>
                <a
                    className={`fmc-pagination__link ${
                        currentPage === maxPaginationNumber
                            ? 'fmc-pagination__active'
                            : ''
                    }`}
                    href='#'
                    role='button'
                    aria-current={afterAriaCurrent}
                    title={`${props.pageNumberTitle} ${maxPaginationNumber}`}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                        event.preventDefault();
                        updateCurrentPage(maxPaginationNumber);
                    }}
                >
                    <span aria-hidden='true'>{maxPaginationNumber}</span>
                </a>
            </li>
        </>
    );

    const updatePageNumberList = () => {
        const previousEllipses =
            currentPage - pageNumbersBetweenEllipses > firstPage;
        const nextEllipses =
            currentPage + pageNumbersBetweenEllipses < maxPaginationNumber;
        if (
            currentPage - pageNumbersBetweenEllipses > 0 &&
            currentPage + pageNumbersBetweenEllipses < maxPaginationNumber
        ) {
            return (
                <>
                    {beforeEllipses}

                    {rangeItems(
                        currentPage - pageNumbersTouchingEllipses,
                        currentPage + pageNumbersTouchingEllipses
                    )}

                    {afterEllipses}
                </>
            );
        } else if (nextEllipses && !previousEllipses) {
            return (
                <>
                    {rangeItems(
                        firstPage,
                        Math.min(
                            minPageNumbersToSkipAfterEllipses,
                            maxPaginationNumber - pageNumbersTouchingEllipses
                        )
                    )}

                    {afterEllipses}
                </>
            );
        } else if (previousEllipses && !nextEllipses) {
            return (
                <>
                    {beforeEllipses}

                    {rangeItems(
                        Math.max(
                            minPageNumbersToSkipBeforeEllipses,
                            maxPaginationNumber - pageNumbersBetweenEllipses
                        ),
                        maxPaginationNumber
                    )}
                </>
            );
        } else {
            return <>{rangeItems(firstPage, maxPaginationNumber)}</>;
        }
    };

    data();

    return (
        <>
            {currentData}
            <nav className='pagination' aria-label='pagination'>
                {props.isMobile ? (
                    <ul className='fmc-pagination fds-hide--desktop'>
                        <li className='fmc-pagination__item'>
                            <a
                                className={`fmc-pagination__prev ${
                                    currentPage === firstPage
                                        ? 'fmc-pagination__disabled'
                                        : ''
                                }`}
                                title={props.prevButtonTitle}
                                href='#'
                                role='button'
                                onClick={(
                                    event: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                    event.preventDefault();
                                    updateCurrentPage(currentPage - 1);
                                }}
                            >
                                <span aria-hidden='true'>
                                    {props.prevButtonLabel}
                                </span>
                            </a>
                        </li>

                        <li className='fmc-pagination__item'>
                            <span className='fmc-pagination__link'>
                                {currentPage} {props.ofLabel}{' '}
                                {maxPaginationNumber}
                            </span>
                        </li>

                        <li className='fmc-pagination__item'>
                            <a
                                className={`fmc-pagination__next ${
                                    currentPage === maxPaginationNumber
                                        ? 'fmc-pagination__disabled'
                                        : ''
                                }`}
                                href='#'
                                role='button'
                                title={props.prevButtonTitle}
                                onClick={(
                                    event: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                    event.preventDefault();
                                    updateCurrentPage(currentPage + 1);
                                }}
                            >
                                <span aria-hidden='true'>
                                    {props.nextButtonLabel}
                                </span>
                            </a>
                        </li>
                    </ul>
                ) : (
                    <ul className='fmc-pagination fds-hide--mobile'>
                        <li className='fmc-pagination__item'>
                            <a
                                className={`fmc-pagination__prev ${
                                    currentPage === firstPage
                                        ? 'fmc-pagination__disabled'
                                        : ''
                                }`}
                                href='#'
                                role='button'
                                title={props.prevButtonTitle}
                                onClick={(
                                    event: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                    event.preventDefault();
                                    updateCurrentPage(currentPage - 1);
                                }}
                            >
                                <span aria-hidden='true'>
                                    {props.prevButtonLabel}
                                </span>
                            </a>
                        </li>
                        {updatePageNumberList()}
                        <li className='fmc-pagination__item'>
                            <a
                                className={`fmc-pagination__next ${
                                    currentPage === maxPaginationNumber
                                        ? 'fmc-pagination__disabled'
                                        : ''
                                }`}
                                href='#'
                                role='button'
                                title={props.nextButtonTitle}
                                onClick={(
                                    event: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                    event.preventDefault();
                                    updateCurrentPage(currentPage + 1);
                                }}
                            >
                                <span aria-hidden='true'>
                                    {props.nextButtonLabel}
                                </span>
                            </a>
                        </li>
                    </ul>
                )}
            </nav>
        </>
    );
};

export default Pagination;
