import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface SwipePoint {
    x: number;
    y: number;
}

export const useSwipe = (
    onSwipeStart: (swipeStartPoint: SwipePoint) => void,
    onSwipeMove: (swipeCurrentPoint: SwipePoint) => void,
    onSwipeEnd: () => void
): React.MutableRefObject<HTMLElement | null> => {
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const ref = useRef<HTMLElement | null>(null);

    type SwipeEvent = PointerEvent | TouchEvent | MouseEvent;

    function isPointerEvent(event: SwipeEvent): event is PointerEvent {
        return typeof (event as PointerEvent).pointerId !== 'undefined';
    }

    function isTouchEvent(event: SwipeEvent): event is TouchEvent {
        return Boolean((event as TouchEvent).touches);
    }

    function moreThanOneTouchPoint(event: TouchEvent): boolean {
        return event.touches && event.touches.length > 1;
    }

    function browserSupportsPointerEvents(): boolean {
        return !!window.PointerEvent;
    }

    function startCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    }

    function stopCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    }

    function addMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.addEventListener('mousemove', onMouseMove, true);
        document.addEventListener('mouseup', onMouseUp, true);
    }

    function removeMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.removeEventListener('mousemove', onMouseMove, true);
        document.removeEventListener('mouseup', onMouseUp, true);
    }

    const getEventPoint = useCallback((event: SwipeEvent): SwipePoint => {
        if (isTouchEvent(event)) {
            return {
                x: event.targetTouches[0].clientX,
                y: event.targetTouches[0].clientY,
            };
        } else {
            return {
                x: event.clientX,
                y: event.clientY,
            };
        }
    }, []);

    const handleSwipeMove = useCallback(
        (event: SwipeEvent) => {
            if (isSwiping) {
                onSwipeMove(getEventPoint(event));
            }
        },
        [onSwipeMove, isSwiping, getEventPoint]
    );

    const handleSwipeEnd = useCallback(
        (event: SwipeEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && event.touches.length > 0) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                stopCapturingPointerMovement(event);
            } else {
                removeMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(false);
            onSwipeEnd();
        },
        [onSwipeEnd, handleSwipeMove, setIsSwiping]
    );

    const handleSwipeStart = useCallback(
        (event: SwipeEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && moreThanOneTouchPoint(event)) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                startCapturingPointerMovement(event);
            } else {
                addMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(true);
            onSwipeStart(getEventPoint(event));
        },
        [
            onSwipeStart,
            handleSwipeMove,
            handleSwipeEnd,
            setIsSwiping,
            getEventPoint,
        ]
    );

    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            if (window.PointerEvent) {
                currentRef.addEventListener(
                    'pointerdown',
                    handleSwipeStart,
                    true
                );
                currentRef.addEventListener(
                    'pointermove',
                    handleSwipeMove,
                    true
                );
                currentRef.addEventListener('pointerup', handleSwipeEnd, true);
                currentRef.addEventListener(
                    'pointercancel',
                    handleSwipeEnd,
                    true
                );
            } else {
                currentRef.addEventListener(
                    'touchstart',
                    handleSwipeStart,
                    true
                );
                currentRef.addEventListener('touchmove', handleSwipeMove, true);
                currentRef.addEventListener('touchend', handleSwipeEnd, true);
                currentRef.addEventListener(
                    'touchcancel',
                    handleSwipeEnd,
                    true
                );
                currentRef.addEventListener(
                    'mousedown',
                    handleSwipeStart,
                    true
                );
            }
        }
        return () => {
            if (window.PointerEvent) {
                currentRef.removeEventListener(
                    'pointerdown',
                    handleSwipeStart,
                    true
                );
                currentRef.removeEventListener(
                    'pointermove',
                    handleSwipeMove,
                    true
                );
                currentRef.removeEventListener(
                    'pointerup',
                    handleSwipeEnd,
                    true
                );
                currentRef.removeEventListener(
                    'pointercancel',
                    handleSwipeEnd,
                    true
                );
            }
        };
    }, [ref, handleSwipeStart, handleSwipeMove, handleSwipeEnd]);

    return ref;
};
