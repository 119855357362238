import React, { useRef } from 'react';
import { FMAccordionProps } from '../../types/fm-accordion.types';
import './fm-accordion.scss';
import FMAccordionItem from './fm-accordion-item';

const FMAccordion = (props: FMAccordionProps) => {
    const { panels, handleClick, title } = props;
    const accordionRef = useRef<HTMLDivElement>(null);
    const titleHasMarkup =
        typeof title === 'string' &&
        title?.trim()?.startsWith('<') &&
        title?.trim()?.endsWith('>');

    const closeOtherPanels = (currentPanelIndex: number) => {
        for (let i = 0; i < accordionRef.current.children.length; i++) {
            if (i !== currentPanelIndex) {
                accordionRef.current.children[i].removeAttribute('open');
            }
        }
    };

    return (
        <section className='fm-accordion'>
            {props.title && (
                <header className='fm-accordion__heading'>
                    {titleHasMarkup ? (
                        <section
                            dangerouslySetInnerHTML={{
                                __html: props.title.toString(),
                            }}
                        />
                    ) : (
                        <h2 className='fm-accordion__title'>{props.title}</h2>
                    )}
                </header>
            )}
            <section className='fm-accordion__panels' ref={accordionRef}>
                {panels?.map((panel, index) => (
                    <FMAccordionItem
                        key={`fm-accordion-panel-${index}-${panel}`}
                        {...panel}
                        {...(handleClick ? { handleClick: handleClick } : {})}
                        closeOtherPanels={() => closeOtherPanels(index)}
                    />
                ))}
            </section>
        </section>
    );
};

export default FMAccordion;
