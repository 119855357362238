import React from 'react';
import { FloatingActionButtonProps } from '../../types/floatingActionbutton.types';

const variants = {
    chat: 'chat',
    scrollToTop: 'chevron-up',
};

const FloatingActionButton = React.forwardRef(function FloatingActionButton(
    props: FloatingActionButtonProps,
    ref: React.Ref<HTMLButtonElement>
) {
    const {
        variant,
        iconCustomClass,
        children,
        brand = 'ford',
        label,
        buttonCustomClass,
        textCustomClass,
        onClick,
        disabled,
        ...rest
    } = props;
    const FABClasses = () => {
        const classes: string[] = ['fds-icon', 'fds-icon--24'];
        if (variants[variant] && brand === 'ford') {
            classes.push(`fds-font--ford-icons__${variants[variant]}`);
        } else if (variants[variant] && brand === 'lincoln') {
            classes.push('');
        }
        if (iconCustomClass) {
            classes.push(props.iconCustomClass);
        }
        return classes.join(' ');
    };

    const onButtonClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <button
            ref={ref}
            className={`fmc-fab ${buttonCustomClass || ''}`}
            onClick={onButtonClick}
            {...(disabled ? { disabled: true } : {})}
            {...rest}
        >
            <span className={FABClasses()} />
            {label && (
                <span className={`fmc-fab__text ${textCustomClass || ''}`}>
                    {label}
                </span>
            )}
            {children && children}
        </button>
    );
});

export default FloatingActionButton;
