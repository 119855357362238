import { FMTabsProps } from '../../types/fm-tabs.types';
import FMAccordion from '../fm-accordion/fm-accordion';
import React, { useEffect, useState } from 'react';
import { FMAccordionProps } from '../../types/fm-accordion.types';
import FMTabs from '../fm-tabs/fm-tabs';

const MOBILE_BREAKING_POINT = 992;

const FMTabsWithAccordion = (props: FMTabsProps) => {
    const accordionProps = {
        theme: props?.theme,

        panels: props?.panels,
    } as FMAccordionProps;

    const [isMobile, setIsMobile] = useState<boolean>(
        window?.innerWidth < MOBILE_BREAKING_POINT
    );

    const viewToggler = () => {
        setIsMobile(window?.innerWidth < MOBILE_BREAKING_POINT);
    };

    useEffect(() => {
        window.addEventListener('resize', viewToggler);
        return () => {
            window.removeEventListener('resize', viewToggler);
        };
    }, [window?.innerWidth]);
    return (
        <>
            {!isMobile && props && <FMTabs {...props} />}
            {isMobile && accordionProps && <FMAccordion {...accordionProps} />}
        </>
    );
};

export default FMTabsWithAccordion;
