import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import '../card-carousel/card-carousel.scss';
import { CardCarouselProps } from '../../types/card-carousel.types';
import Cards from '../cards/cards';
import { SwipePoint, useSwipe } from '../../hooks/use-swipe';

const CardCarousel = (props: CardCarouselProps) => {
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const itemsRef = useRef<HTMLDivElement>(null);
    const maxCardIndex = props.cards.cards.length - 1;
    const [visibleRange, setVisibleRange] = useState<{
        start: number;
        end: number;
    }>();

    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            swipeStartX && setSwipeOffsetX(swipePoint.x - swipeStartX);
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (let cardIndex = 0; cardIndex <= maxCardIndex; cardIndex++) {
            if (itemsRef.current) {
                const parentLeft =
                    itemsRef.current.getBoundingClientRect().left;
                const itemLeft = itemsRef.current
                    .getElementsByClassName('carousel-wrap__items-item')
                    .item(cardIndex)
                    ?.getBoundingClientRect().left;
                if (itemLeft) {
                    const cardPosX = itemLeft - parentLeft;
                    if (
                        Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                        Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
                    ) {
                        closestCardIndex = cardIndex;
                        closestCardX = cardPosX;
                    }
                }
            }
        }
        setCurrentCardIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        itemsRef,
        swipeOffsetX,
        setSwipeStartX,
        setCurrentCardIndex,
        maxCardIndex,
        cardsPositionX,
    ]);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const containerRef = useSwipe(
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const updateCardPositions = useCallback(() => {
        if (itemsRef.current) {
            const parentLeft = itemsRef.current.getBoundingClientRect().left;
            const itemLeft = itemsRef.current
                .getElementsByClassName('carousel-wrap__items-item')
                .item(currentCardIndex)
                ?.getBoundingClientRect().left;
            if (itemLeft) {
                const offset = itemLeft - parentLeft;
                setCardsPositionX(-offset);
            }
        }
    }, [itemsRef, currentCardIndex, setCardsPositionX]);

    useEffect(() => {
        window.addEventListener('resize', updateCardPositions);
        return () => {
            window.removeEventListener('resize', updateCardPositions);
        };
    }, [updateCardPositions]);

    const calculateVisibleRange = useCallback(() => {
        if (itemsRef.current) {
            let endVisible = props.cards.cards.length;
            Array.from(
                itemsRef.current.getElementsByClassName(
                    'carousel-wrap__items-item'
                )
            ).forEach((element, index) => {
                element.getBoundingClientRect().left < window.innerWidth &&
                    element.getBoundingClientRect().right < window.innerWidth &&
                    (endVisible = index + 1);
            });
            setVisibleRange({ start: currentCardIndex, end: endVisible });
        }
    }, [itemsRef, currentCardIndex]);

    useEffect(() => {
        itemsRef.current &&
            itemsRef.current.addEventListener(
                'transitionend',
                calculateVisibleRange
            );
        return () => {
            itemsRef.current &&
                itemsRef.current.removeEventListener(
                    'transitionend',
                    calculateVisibleRange
                );
        };
    }, [calculateVisibleRange, itemsRef]);

    useEffect(() => {
        calculateVisibleRange();
    }, []);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentCardIndex, updateCardPositions]);

    const moveToNextCard = () => {
        currentCardIndex < maxCardIndex &&
            setCurrentCardIndex(currentCardIndex + 1);
    };

    const moveToPreviousCard = () => {
        currentCardIndex > 0 && setCurrentCardIndex(currentCardIndex - 1);
    };

    return (
        <div
            className={[
                'js-fmc-carousel-container fmc-carousel-container carousel',
                props.className,
            ].join(' ')}
        >
            <div
                className='carousel-wrap fmc-carousel'
                ref={(instance: HTMLDivElement) =>
                    (containerRef.current = instance)
                }
            >
                <div
                    ref={itemsRef}
                    className='carousel-wrap__items'
                    style={{
                        transform: `translateX(${
                            cardsPositionX + swipeOffsetX
                        }px)`,
                        transition: swipeStartX
                            ? 'none'
                            : 'transform 300ms linear',
                    }}
                >
                    {props.cards.cards.map((card, index) => {
                        return (
                            <div
                                className='carousel-wrap__items-item'
                                data-testid='carousel-card-cards'
                                key={index}
                            >
                                <Cards cards={[card]} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div>
                <div
                    className={`${
                        props.cards.cards.length > 3
                            ? 'fmc-carousel-indicator'
                            : 'fmc-carousel-indicator-desktop'
                    }`}
                    data-testid='carousel-indicator'
                >
                    <button
                        className={`fmc-carousel-indicator__directional-button fmc-carousel-indicator__directional-button--previous ${
                            currentCardIndex === 0
                                ? 'fmc-carousel-indicator__button--disabled'
                                : ''
                        }`}
                        aria-label='previous button'
                        onClick={moveToPreviousCard}
                    />
                    <div className='fmc-carousel-indicator__pagination'>
                        <span
                            className='fmc-carousel-indicator__pagination-text'
                            aria-live={props.ariaLive || 'polite'}
                            role={props.role || 'alert'}
                            aria-label={props.ariaLabel || 'current card'}
                        >
                            {visibleRange && (
                                <div className='fmc-carousel-indicator__pagination-text-desktop'>
                                    {visibleRange.start + 1} -{' '}
                                    {visibleRange.end}{' '}
                                    {props.paginationText || 'of '}
                                    {props.cards.cards.length}
                                </div>
                            )}
                            {!visibleRange &&
                                `${currentCardIndex + 1} ${
                                    props.paginationText || 'of '
                                } ${props.cards.cards.length}`}
                            {visibleRange && (
                                <div className='fmc-carousel-indicator__pagination-text-mobile'>
                                    {visibleRange.start + 1}/
                                    {props.cards.cards.length}
                                </div>
                            )}
                        </span>
                    </div>
                    <button
                        className={`fmc-carousel-indicator__directional-button fmc-carousel-indicator__directional-button--next ${
                            visibleRange?.end === props.cards.cards.length
                                ? 'fmc-carousel-indicator__button--disabled'
                                : ''
                        }`}
                        aria-label='next button'
                        onClick={moveToNextCard}
                        disabled={
                            visibleRange?.end === props.cards.cards.length
                        }
                        data-testid='nextButton'
                    />
                </div>
            </div>
        </div>
    );
};

export default CardCarousel;
