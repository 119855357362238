import React, { useEffect, useRef, useState } from 'react';
import { NotificationBannerProps } from '../../types/notificationBanner.types';
import './notificationBanner.scss';

const NotificationBanner = (props: NotificationBannerProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const contentRef = useRef<HTMLSpanElement | null>(null);
    const classes: string[] = [];
    const topBannerClasses = () => {
        classes.push('fmc-top-banner');
        if (props.actionType === 'persistent') {
            classes.push('fmc-top-banner--persistent');
        }
        classes.push(`fmc-top-banner--${props.variant}`);

        return classes.join(' ');
    };
    const handleDismissButtonClick = () => {
        setIsVisible(false);
    };
    const onAnchorClick = () => {
        if (props.onAnchorClick) props.onAnchorClick();
    };
    useEffect(() => {
        setIsVisible(true); // animation on load
        if (
            props.onAnchorClick &&
            contentRef.current &&
            contentRef.current?.getElementsByTagName('a').length > 0
        ) {
            contentRef.current
                .getElementsByTagName('a')[0]
                .addEventListener('click', onAnchorClick);
        }

        return () => {
            if (
                props.onAnchorClick &&
                contentRef.current &&
                contentRef.current?.getElementsByTagName('a').length > 0
            ) {
                contentRef.current
                    .getElementsByTagName('a')[0]
                    .removeEventListener('click', onAnchorClick);
            }
        };
    }, []);
    return (
        <div
            className={topBannerClasses()}
            {...(!isVisible ? { hidden: !isVisible } : {})}
        >
            <div className='fmc-top-banner__inner'>
                <span className='fmc-top-banner__content'>
                    <span
                        ref={contentRef}
                        dangerouslySetInnerHTML={{ __html: props.content }}
                    />
                    {props.actionable && (
                        <span className='fmc-top-banner__chevron fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right'></span>
                    )}
                </span>
                {props.actionType === 'dismissible' && (
                    <button
                        className='fmc-top-banner__dismiss'
                        aria-label={props.dismissAriaLabel}
                        onClick={handleDismissButtonClick}
                    ></button>
                )}
            </div>
        </div>
    );
};
export default NotificationBanner;
