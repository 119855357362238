import React from 'react';
import { CardFiftyFiftyProps } from '../../types/cardfiftyfifty.types';
import './cardfiftyfifty.scss';
import Button from '../button/button';
import FMUtility from '../../fm-utils/fm-util';

const CardFiftyFifty = (props: CardFiftyFiftyProps) => {
    const styles = {
        backgroundImage: `url(${props.media.src})`,
    };
    const isVideo = () => {
        if (props.media.type) return `fmc-cards__${props.media.type}`;
        return '';
    };
    const color = () => {
        if (props.backgroundColor === 'primary')
            return 'card-display-right-background';
        return 'card-display-right';
    };
    const displayCard = () => {
        let displayType = 'card-display card-display-row-reverse';
        switch (props.mediaSide) {
            case 'top-left':
                displayType = 'card-display top-left';
                break;

            case 'bottom-right':
                displayType = 'card-display bottom-right';
                break;

            default:
                break;
        }

        return displayType;
    };
    const videoClick = () => {
        window.location.href = props.media.href;
    };
    return (
        <>
            <div className='card-fifty-fifty'>
                <div className='fmc-cards__card fmc-cards__card--horizontal fmc-cards__card--horizontal-25 fds-layout-grid__cell--span-12'>
                    <div className={`${displayCard()}`}>
                        <div className='card-display-left'>
                            {props.media && (
                                <div
                                    className={`fmc-cards__media-area ${isVideo()} fmc-cards__video--${
                                        props.media.width
                                    } `}
                                >
                                    {props.media.type === 'video' ? (
                                        <div
                                            className='card-display-left-video-img bannerImage'
                                            aria-label='Card image'
                                            role='button'
                                            style={styles}
                                            onClick={videoClick}
                                            onKeyDown={videoClick}
                                            tabIndex={0}
                                        />
                                    ) : (
                                        <div
                                            className='card-display-left-img bannerImage'
                                            aria-label='Card image'
                                            style={styles}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={`${color()}`}>
                            <div className='fmc-cards__content card-display-content'>
                                {FMUtility.isMarkup(props.title?.toString()) ? (
                                    <div
                                        className='fmc-cards__title card-display-title'
                                        dangerouslySetInnerHTML={{
                                            __html: props.title?.toString(),
                                        }}
                                    />
                                ) : (
                                    <div className='fmc-cards__title card-display-title'>
                                        {props.title}
                                    </div>
                                )}
                                {FMUtility.isMarkup(props.body?.toString()) ? (
                                    <div
                                        className='fmc-cards__body card-display-body'
                                        dangerouslySetInnerHTML={{
                                            __html: props.body?.toString(),
                                        }}
                                    ></div>
                                ) : (
                                    <div className='fmc-cards__body card-display-body'>
                                        {props.body}
                                    </div>
                                )}
                                <div className='card-display-button'>
                                    {props.button && (
                                        <Button
                                            label={props.button.label}
                                            type={props.button.type}
                                            outlined={props.button.outlined}
                                            href={props.button.href}
                                            chevron={props.button.chevron}
                                            disabled={props.button.disabled}
                                            onClick={props.button.onClick}
                                            preventDefault={
                                                props.button.preventDefault
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardFiftyFifty;
