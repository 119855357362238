import React from 'react';
import { BreadcrumbsProps } from '../../types/breadcrumbs.types';
import './../../main.scss';

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick(e);
        }
    };
    return (
        <>
            {props.items && props.items.length > 0 && (
                <nav aria-label='breadcrumbs'>
                    <ul className='fmc-breadcrumbs'>
                        {props.items.map((item, index) => (
                            <>
                                {index !== props.items.length - 1 ? (
                                    <>
                                        <li key={`breadcrumb_item_${index}`}>
                                            <a
                                                href={item.href}
                                                onClick={(e) => onClick(e)}
                                                className='fmc-breadcrumbs__link'
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                        <li
                                            key={`breadcrumb_separator_${index}`}
                                        >
                                            <span className='fds-icon fds-font--ford-icons__chevron-right fds-color__text--gray3'></span>
                                        </li>
                                    </>
                                ) : (
                                    <li key={`breadcrumb_item_${index}`}>
                                        <span
                                            className='fmc-breadcrumbs__current'
                                            aria-current='page'
                                        >
                                            {item.name}
                                        </span>
                                    </li>
                                )}
                            </>
                        ))}
                    </ul>
                </nav>
            )}
        </>
    );
};

export default Breadcrumbs;
