import React from 'react';
import {
    Card,
    MEDIA_WIDTH_TYPE,
    Media,
    CardsProps,
} from '../../types/cards.types';
import './card.scss';
import Button from '../button/button';

const Cards = (props: CardsProps) => {
    const isQuiet = (quiet: boolean) => {
        return quiet ? `fmc-cards__card--quiet` : ``;
    };
    const isVideo = (media: Media) => {
        return media ? `fmc-cards__${media.type} ` : ``;
    };
    const isHorizontal = (horizontal: boolean, reverse: boolean) => {
        return horizontal
            ? `fmc-cards__card--horizontal ${
                  reverse ? 'fmc-cards__card--horizontal-reverse' : ``
              }`
            : ``;
    };
    const truncateEllipses = (body: string) => {
        return body.length > 280 ? `${body.substring(0, 277)}...` : body;
    };
    const videoWidth = (width: MEDIA_WIDTH_TYPE) => {
        return width ? `fmc-cards__video--${width}` : ``;
    };
    const multipleCards = () => {
        return props.cards.length > 1 ? 'fds-layout-grid__inner' : '';
    };

    const Card = (card: Card) => {
        return (
            <>
                <div className={`fmc-cards__media-area`}>
                    {card.media && (
                        <div
                            className={`fmc-cards__media-area ${isVideo(
                                card.media
                            )} ${videoWidth(card.media.width)}`}
                            data-testid='card-media'
                        >
                            <img src={card.media.src} alt={card.media.alt} />
                        </div>
                    )}
                    {card.alert && (
                        <div
                            data-testid='card-alert'
                            className={`fmc-cards__alert fmc-cards__alert--${card.alert.type}`}
                        >
                            {card.alert.text}
                        </div>
                    )}
                </div>
                <div className='fmc-cards__content-wrapper'>
                    <div className='fmc-cards__content'>
                        <h2
                            className='fmc-cards__title'
                            data-testid='card-title'
                        >
                            {card.title}
                        </h2>
                        {card.subTitle && (
                            <h3
                                className='fmc-cards__subtitle'
                                data-testid='card-subtitle'
                            >
                                {card.subTitle}
                            </h3>
                        )}
                        {card.body && (
                            <p
                                className='fmc-cards__body'
                                data-testid='card-body'
                            >
                                {truncateEllipses(card.body)}
                            </p>
                        )}
                        {card.button || card.secondaryButton ? (
                            <div className='fmc-cards__footer'>
                                {card.button && (
                                    <Button
                                        {...card.button}
                                        data-testid='card-button'
                                    />
                                )}
                                {card.secondaryButton && (
                                    <Button {...card.secondaryButton} />
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </>
        );
    };
    return (
        props.cards.length > 0 && (
            <div className={`fmc-cards ${multipleCards()}`}>
                {props.cards.map((card: Card, index: number) => {
                    return (
                        <div
                            className={`fmc-cards__card ${isHorizontal(
                                card.horizontal,
                                card.reverse
                            )} ${isQuiet(card.quiet)} fds-layout-grid__cell`}
                            key={index}
                        >
                            <Card {...card} />
                        </div>
                    );
                })}
            </div>
        )
    );
};

export default Cards;
