import React from 'react';
import { TrackerProps } from '../../types/tracker.types';

const Tracker = (props: TrackerProps) => {
    const { steps, currentStep, simple, disabled } = props;
    const stepCount = steps.length;
    const getTrackerClass = (stepNumber, activeStep) => {
        if (activeStep === stepNumber) {
            return 'fmc-tracker__dot--active';
        } else if (stepNumber < activeStep) {
            return 'fmc-tracker__dot--success';
        } else {
            return '';
        }
    };
    const getCheckmark = (stepNumber) => {
        return stepNumber < currentStep ? (
            <span className='fds-icon fds-font--ford-icons__success'></span>
        ) : (
            `${`${stepNumber}/${stepCount}`}`
        );
    };
    const sortedSteps = [...steps].sort((a, b) => a.order - b.order);
    const mapSteps = () => {
        return sortedSteps.map((step) => {
            const isAriaCurrent = step.order === currentStep ? 'step' : 'false';
            return (
                <li
                    key={step.order}
                    className={`fmc-tracker__step ${
                        step.order < currentStep
                            ? 'fmc-tracker__step--complete'
                            : ''
                    }`}
                >
                    <button
                        disabled={step.order > currentStep && disabled}
                        className={`fmc-tracker__dot ${getTrackerClass(
                            step.order,
                            currentStep
                        )}`}
                        aria-label={step.title ? step.title : ''}
                        aria-current={isAriaCurrent}
                        onClick={() => props.onClick(step.order)}
                    >
                        {!simple && getCheckmark(step.order)}
                    </button>
                    <p
                        className={`fmc-tracker__title ${
                            step.order > currentStep
                                ? 'fmc-tracker__title--future'
                                : ''
                        }
                            ${
                                step.order > currentStep && disabled
                                    ? ' fmc-tracker__title--disabled'
                                    : ''
                            }
                        `}
                    >
                        {step.title}
                    </p>
                </li>
            );
        });
    };

    return (
        <>
            <ul
                className={`fmc-tracker ${simple ? 'fmc-tracker--simple' : ''}`}
            >
                {mapSteps()}
            </ul>
        </>
    );
};

export default Tracker;
