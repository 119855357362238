import React from 'react';
import { ButtonProps, CHEVRON } from '../../types/button.types';
import './../../main.scss';

const Button = (props: ButtonProps) => {
    const classes: string[] = [];
    const buttonClasses = () => {
        if (props.type === 'standard') {
            classes.push('fmc-button');
            if (props.noShadow) classes.push('fmc-button--no-shadow');
        } else {
            classes.push('fmc-text-button');
            if (props.chevron) {
                classes.push(`fmc-text-button--chevron-${props.chevron}`);
            }
        }
        if (props.type !== 'text' && props.outlined)
            classes.push('fmc-button--outlined');
        return classes.join(' ');
    };
    const chevron = (direction: CHEVRON) => {
        if (props.type === 'standard')
            return (
                <span
                    className={`fds-icon fds-font--ford-icons__chevron-${direction} fds-icon--offset-${direction}`}
                ></span>
            );
    };
    const onClick = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (props.onClick) {
            props.preventDefault && e.preventDefault();
            props.onClick(e);
        }
    };
    return (
        <>
            {props.href && (
                <a
                    className={buttonClasses()}
                    {...(props.disabled ? { disabled: true } : {})}
                    onClick={(e) => onClick(e)}
                    href={props.href}
                    data-testid={props.label}
                >
                    {props.label}
                </a>
            )}
            {!props.href && (
                <button
                    className={buttonClasses()}
                    {...(props.disabled ? { disabled: true } : {})}
                    {...(props.theme && props.theme === 'dark'
                        ? { 'data-dark': true }
                        : { 'data-light': true })}
                    onClick={(e) => onClick(e)}
                    data-testid={props.label}
                >
                    {props.type === 'standard' &&
                        props.chevron &&
                        props.chevron === 'left' &&
                        chevron('left')}
                    {props.label}
                    {props.type === 'standard' &&
                        props.chevron &&
                        props.chevron === 'right' &&
                        chevron('right')}
                </button>
            )}
        </>
    );
};

export default Button;
