import React from 'react';
import { DialogProps } from '../../types/dialog.types';
import './dialog.scss';
const Dialog = (props: DialogProps) => {
    const {
        content,
        dialogAriaLabel,
        onClose,
        padding,
        centered,
        open,
        closeButtonAriaLabel,
        fiftyFifty,
    } = props;
    return (
        <>
            <div className={`${fiftyFifty ? 'fifty-fifty' : ''}`}>
                <div
                    className={`fmc-dialog ${
                        open ? 'fmc-dialog--opened' : 'fmc-dialog__close'
                    } ${centered ? 'fds-align--center' : ''}
      
                `}
                    role='dialog'
                    aria-modal='true'
                    aria-label={dialogAriaLabel}
                >
                    <div
                        className={`fmc-dialog__content ${
                            padding === false
                                ? 'fmc-dialog__content--no-padding'
                                : ''
                        }`}
                    >
                        <button
                            className='fmc-dialog__close fds-icon fds-font--ford-icons__clear'
                            aria-label={closeButtonAriaLabel}
                            data-testid='closeButton'
                            onClick={() => onClose && onClose()}
                        ></button>
                        {content}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dialog;
