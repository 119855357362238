import React from 'react';
import { ChevronProps } from '../../types/chevron.types';

const Chevron = (props: ChevronProps) => {
    return (
        <span
            className={`fds-icon fds-font--ford-icons__chevron-${props.direction} fds-icon--offset-${props.direction}`}
        ></span>
    );
};

export default Chevron;
