import React, { forwardRef } from 'react';
import './fm-dropdown.scss';
import { FMDropdownProps } from '../../types/fm-dropdown.types';

const FMDropdown = forwardRef<HTMLSelectElement, FMDropdownProps>(
    function FMDropdown(
        {
            renderOwnOption,
            value,
            name,
            state,
            customClass,
            disabled,
            options,
            header,
            footer,
            id,
            ariaLabel,
            ...props
        },
        ref
    ) {
        const selectElementId = name || id;
        const inputClasses = () => {
            const classes: string[] = ['fm-dropdown'];
            if (state) {
                classes.push(`fm-dropdown--${state}`);
            }
            if (customClass) {
                classes.push(customClass);
            }
            return classes.join(' ').trim();
        };
        const supportTextClasses = () => {
            const classes: string[] = ['fm-dropdown-support-text'];
            if (state) {
                classes.push(`fm-dropdown-support-text--${state}`);
            }
            return classes.join(' ').trim();
        };
        function headerClasses() {
            return ['fm-dropdown-header', `${disabled ? '--disabled' : ''}`]
                .join(' ')
                .trim();
        }
        return (
            <div className={'fm-dropdown-container'}>
                {header && (
                    <label
                        {...(selectElementId
                            ? { htmlFor: selectElementId }
                            : {})}
                        className={headerClasses()}
                    >
                        {header}
                    </label>
                )}
                <select
                    ref={ref}
                    {...props}
                    {...(typeof value === 'undefined' ? {} : { value })}
                    {...(selectElementId ? { id: selectElementId } : {})}
                    {...(disabled ? { disabled: true } : {})}
                    className={inputClasses()}
                    {...(name ? { name } : {})}
                    {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
                    data-testid={props.dataTestId}
                    onChange={props.onChange}
                >
                    {typeof renderOwnOption === 'function' ? (
                        renderOwnOption(options)
                    ) : (
                        <>
                            <option>{''}</option>
                            {options?.map((option, index) => (
                                <option
                                    value={option.value || option}
                                    key={`${option.value || option}-${index}`}
                                >
                                    {option.label || option}
                                </option>
                            ))}
                        </>
                    )}
                </select>
                {footer && (
                    <div
                        {...(selectElementId
                            ? { 'aria-labelledby': selectElementId }
                            : {})}
                        className={supportTextClasses()}
                    >
                        {footer}
                    </div>
                )}
            </div>
        );
    }
);

FMDropdown.displayName = 'FMDropdown';

export default FMDropdown;
