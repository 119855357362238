import React, { useEffect, useState } from 'react';
import { ToggleProps } from '../../types/toggle.types';
import './../../main.scss';

const Toggle = (props: ToggleProps) => {
    const [isToggled, setIsToggled] = useState(props.checked);

    const isToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsToggled(!isToggled);
        props.onChange && props.onChange(e);
    };

    useEffect(() => {
        if (props.checked !== isToggled) setIsToggled(props.checked);
    }, [props.checked]);

    return (
        <label
            className='fmc-toggle fmc-toggle--skeuomorphic'
            {...(props.disabled ? { disabled: true } : {})}
        >
            <span className='fmc-toggle__label'>{props.labelText}</span>
            {!props.optionTextTrack ? (
                <span
                    className={`fmc-toggle__unchecked-option fds-color__text--${props.optionTextColor}`}
                    aria-hidden='true'
                >
                    {props.unCheckedOptionText}
                </span>
            ) : (
                ''
            )}
            <input
                aria-label={props.labelText}
                type='checkbox'
                name='toggle-button'
                value={
                    isToggled
                        ? props.checkedOptionText
                        : props.unCheckedOptionText
                }
                checked={isToggled}
                onChange={(e) => isToggle(e)}
                {...(props.disabled ? { disabled: true } : {})}
            />

            {props.optionTextTrack ? (
                <span className='fmc-toggle__track'>
                    <span
                        className={`fmc-toggle__checked-option fds-color__text--${props.optionTextColor}`}
                        aria-hidden='true'
                    >
                        {props.checkedOptionText}
                    </span>
                    <span
                        className={`fmc-toggle__unchecked-option fds-color__text--${props.optionTextColor}`}
                        aria-hidden='true'
                    >
                        {props.unCheckedOptionText}
                    </span>
                </span>
            ) : (
                <>
                    <span className='fmc-toggle__track'></span>
                    <span
                        className={`fmc-toggle__checked-option fds-color__text--${props.optionTextColor}`}
                        aria-hidden='true'
                    >
                        {props.checkedOptionText}
                    </span>
                </>
            )}
        </label>
    );
};

export default Toggle;
