import React, { forwardRef } from 'react';
import {
    FMButtonElementProps,
    FMButtonProps,
    FMLinkElementProps,
} from '../../types/fm-button.types';
import { CHEVRON } from '../../types/button.types';
import FMUtility from '../../fm-utils/fm-util';
import './../../main.scss';
import './fm-button.scss';

const FMButton = forwardRef<
    HTMLAnchorElement | HTMLButtonElement,
    FMButtonProps
>(function FMButton(
    {
        htmlButtonType = 'button',
        preventDefault,
        type,
        altColorScheme: FM_BTN_ALT_COLOR_SCHEME,
        disabled,
        theme,
        className,
        label,
        href,
        ariaLabel,
        target,
        ...props
    },
    ref
) {
    const onClick = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (props.onClick) {
            preventDefault && e.preventDefault();
            props.onClick(e);
        }
    };
    const chevron = (direction: CHEVRON) => {
        return (
            <span
                className={`fds-icon fds-font--ford-icons__chevron-${direction} fds-icon--offset-${direction}`}
            />
        );
    };

    function fmButtonClasses() {
        return [
            'fm-btn',
            `fm-btn--${type}`,
            `${disabled ? 'fm-btn__disabled' : ''}`,
            `${theme === 'dark' ? 'fm-btn__dark' : 'fm-btn__light'}`,
            `${className ? className : ''}`,
            `${FM_BTN_ALT_COLOR_SCHEME ? '--alt' : ''}`,
        ]
            .join(' ')
            .trim();
    }

    return (
        <>
            {href && label != null && (
                <a
                    {...(props as FMLinkElementProps)}
                    ref={ref as React.Ref<HTMLAnchorElement>}
                    className={fmButtonClasses()}
                    onClick={(e) => onClick(e)}
                    href={href}
                    {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
                    target={target || '_self'}
                >
                    {props?.chevron === 'left' && chevron('left')}
                    {FMUtility.isMarkup(label?.toString()) ? (
                        <span
                            className={'fm-btn__text'}
                            dangerouslySetInnerHTML={{
                                __html: label?.toString(),
                            }}
                        />
                    ) : (
                        <span className={'fm-btn__text'}>{label}</span>
                    )}

                    {props?.chevron === 'right' && chevron('right')}
                </a>
            )}
            {!href && label != null && (
                <button
                    {...(props as FMButtonElementProps)}
                    type={htmlButtonType}
                    ref={ref as React.Ref<HTMLButtonElement>}
                    className={fmButtonClasses()}
                    onClick={(e) => onClick(e)}
                    {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
                    {...(disabled ? { disabled: true } : {})}
                >
                    {props?.chevron === 'left' && chevron('left')}
                    {FMUtility.isMarkup(label?.toString()) ? (
                        <span
                            className={'fm-btn__text'}
                            dangerouslySetInnerHTML={{
                                __html: label?.toString(),
                            }}
                        />
                    ) : (
                        <span className={'fm-btn__text'}>{label}</span>
                    )}

                    {props?.chevron === 'right' && chevron('right')}
                </button>
            )}
        </>
    );
});

FMButton.displayName = 'FMButton';

export default FMButton;
