import React, { useEffect, useState } from 'react';
import {
    SnackbarButtonProps,
    SnackbarDismissProps,
    SnackbarLinkProps,
    SnackbarProps,
} from '../../types/snackbar.types';
import './snackbar.scss';

const SnackbarCta = (props: SnackbarLinkProps & SnackbarButtonProps) => (
    <>
        {props.snackbarCtaText &&
            (props.snackbarCtaLink ? (
                <a
                    {...props}
                    rel='noopener noreferrer'
                    href={props.snackbarCtaLink}
                    className='fmc-snackbar__cta'
                >
                    {props.snackbarCtaText}
                </a>
            ) : (
                <button {...props} className='fmc-snackbar__cta'>
                    {props.snackbarCtaText}
                </button>
            ))}
    </>
);

const SnackbarDismissButton = (props: SnackbarDismissProps) => (
    <button
        className='fmc-snackbar__dismiss fds-icon fds-font--ford-icons__clear'
        aria-label={props.ariaLabel}
        onClick={props.onClick}
    />
);

const Snackbar = (props: SnackbarProps) => {
    const {
        content,
        open,
        defaultOpen = false,
        fixed = false,
        fixedClassName = '',
        children,
        snackbarCtaText,
        snackbarCtaLink,
        snackbarCtaLinkTarget,
        showDismissButton = false,
        dismissibleSnackbar,
        dismissibleSnackbarTimer,
        dismissButtonAriaLabel,
        snackbarClassName,
        fixedCssStyleObj = {},
        snackbarCssStyleObj = {},
        onOpen,
        onClose,
    } = props;

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(
        open || defaultOpen
    );
    const wrapperClass = fixed
        ? `fmc-snackbar-wrapper fmc-snackbar-wrapper--fixed ${fixedClassName}`
        : '';

    const closeSnackBar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (open != null) {
            setSnackbarOpen(open);
        }
    }, [open]);

    useEffect(() => {
        if (snackbarOpen && onOpen) {
            onOpen(snackbarOpen);
        } else if (onClose) {
            onClose(snackbarOpen);
        }
    }, [snackbarOpen]);

    useEffect(() => {
        if (snackbarOpen && dismissibleSnackbar && dismissibleSnackbarTimer) {
            const snackbarTimer = setTimeout(() => {
                setSnackbarOpen(false);
            }, dismissibleSnackbarTimer);
            return () => {
                clearTimeout(snackbarTimer);
            };
        }
    }, [snackbarOpen, dismissibleSnackbar, dismissibleSnackbarTimer]);

    const childSnackbar =
        typeof children === 'function' ? (
            children(
                snackbarOpen,
                SnackbarCta,
                SnackbarDismissButton,
                setSnackbarOpen
            )
        ) : (
            <div className='fmc-snackbar__content'>{children}</div>
        );

    return snackbarOpen ? (
        <div className={`${wrapperClass}`} style={fixedCssStyleObj}>
            <div
                className={`fmc-snackbar ${snackbarClassName || ''}`}
                style={snackbarCssStyleObj}
            >
                {children ? (
                    childSnackbar
                ) : (
                    <>
                        <div className='fmc-snackbar__content'>{content}</div>
                        <SnackbarCta
                            snackbarCtaText={snackbarCtaText}
                            snackbarCtaLink={snackbarCtaLink}
                            target={snackbarCtaLinkTarget}
                        />
                        {showDismissButton && (
                            <SnackbarDismissButton
                                onClick={closeSnackBar}
                                ariaLabel={dismissButtonAriaLabel}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};
export default Snackbar;
