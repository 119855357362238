import React, { useRef, useState, useEffect } from 'react';
import { FMAccordionItemProps } from '../../types/fm-accordion.types';
import './fm-accordion.scss';

const FMAccordionItem = (props: FMAccordionItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const accordionItemRef = useRef<HTMLDetailsElement>(null);
    const { title, subtitle, panel, handleClick } = props;
    const panelAnimationClass = ['fm-accordion__panel', isOpen ? 'animate' : '']
        .join(' ')
        .trim();
    const bodyHasMarkup =
        typeof panel === 'string' &&
        panel?.trim()?.startsWith('<') &&
        panel?.trim()?.endsWith('>');
    useEffect(() => {
        if (accordionItemRef?.current) {
            if (isOpen) {
                props.closeOtherPanels(props.index);
            } else {
                accordionItemRef.current.removeAttribute('open');
            }
        }
    }, [isOpen]);

    const toggleEventHandler = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        handleClick && handleClick(e);
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    return (
        <details
            ref={accordionItemRef}
            className='fm-accordion-panel__details'
            onToggle={toggleEventHandler}
        >
            <summary className='fm-accordion-panel__header'>
                <span className='fm-accordion-panel__header-heading'>
                    <span className='fm-accordion-panel__header-title'>
                        {title}
                    </span>
                    {subtitle && (
                        <span className='fm-accordion__header-subtitle'>
                            {subtitle}
                        </span>
                    )}
                </span>
            </summary>

            {bodyHasMarkup ? (
                <section
                    className={panelAnimationClass}
                    dangerouslySetInnerHTML={{
                        __html: panel.toString(),
                    }}
                />
            ) : (
                <section className={panelAnimationClass}>{panel}</section>
            )}
        </details>
    );
};
export default FMAccordionItem;
