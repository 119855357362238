import React, { useEffect, useRef } from 'react';
import { FMCAccordion } from '@ford/ford-design-system';
import { AccordionProps } from '../../types/accordion.types';
import './../../main.scss';
import AccordionItem from './accordion-item';

const Accordion = (props: AccordionProps) => {
    const accordionRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (accordionRef.current) {
            new FMCAccordion(accordionRef);
        }
    }, []);

    const onPanelClick = (panelTitle: string, isExpanded: boolean) => {
        props.onPanelClick && props.onPanelClick(panelTitle, isExpanded);
    };

    const { panels, theme } = props;
    const accordionClasses = (() => {
        const classes = ['js-fmc-accordion', 'fmc-accordion', 'bootstrapped'];
        switch (theme) {
            case 'dark-on-light':
                return classes.concat('fds-color__bg--light').join(' ').trim();
            case 'light-on-dark':
                return classes
                    .concat(['fds-color__bg--dark', 'fmc-accordion--light'])
                    .join(' ')
                    .trim();
            case 'light-on-light':
                return classes
                    .concat(['fds-color__bg--light', 'fmc-accordion--light'])
                    .join(' ')
                    .trim();
            default:
                return classes.join(' ').trim();
        }
    })();
    return (
        <div className={accordionClasses} role='presentation'>
            {panels.length > 0 &&
                panels.map((panel, index) => (
                    <AccordionItem
                        key={index}
                        {...panel}
                        index={index}
                        onPanelClick={onPanelClick}
                    />
                ))}
        </div>
    );
};

export default Accordion;
