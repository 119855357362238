import React, { useEffect, useRef, useState } from 'react';
import {
    Segment,
    SegmentedControlProps,
} from '../../types/segmentedControl.types';
import { FMCSegmentedControl } from '@ford/ford-design-system';

const SegmentedControl = (props: SegmentedControlProps) => {
    const segmentedControlRef = useRef();
    const [selectedPanel, setSelectedPanel] = useState<number>(
        props.initialSelection || 1
    );

    const callFmcSegmentedControl = () => {
        try {
            new FMCSegmentedControl(segmentedControlRef.current);
        } catch (error) {
            console.error(
                'Error initializing FMC Segmented Controls ',
                error.message
            );
        }
    };

    const handleSegmentClick = (segmentNumber: number) => {
        props.onClick && props.onClick(segmentNumber);
        callFmcSegmentedControl();
        setSelectedPanel(segmentNumber);
    };

    const buttonComponents = () => {
        return props.segments.map((segment: Segment, index: number) => {
            const panelNumber = index + 1;
            const isSelectedPanel = selectedPanel === panelNumber;
            return (
                <button
                    role='tab'
                    className='fmc-segmented-control__button'
                    aria-selected={isSelectedPanel}
                    aria-controls={`segmented-control-panel${panelNumber}`}
                    id={`segmented-control-button${panelNumber}`}
                    key={`label${panelNumber}`}
                    onClick={() => handleSegmentClick(panelNumber)}
                >
                    <span className='fmc-segmented-control__button-text'>
                        {segment.label}
                        {isSelectedPanel && props.checkMark && (
                            <span className='fds-icon fds-icon--offset-right fds-font--ford-icons__tick'></span>
                        )}
                    </span>
                </button>
            );
        });
    };

    const panelComponents = () => {
        return props.segments.map((segment: Segment, index: number) => {
            const panelNumber = index + 1;
            return (
                <div
                    role='tabpanel'
                    id={`segmented-control-panel${panelNumber}`}
                    className='fmc-segmented-control__panel'
                    aria-labelledby={`segmented-control-button${panelNumber}`}
                    tabIndex={0}
                    key={`content${panelNumber}`}
                >
                    <h3 className='fmc-mt-5'>{segment.label}</h3>
                    <p>{segment.content}</p>
                </div>
            );
        });
    };

    useEffect(() => {
        callFmcSegmentedControl();
    }, []);

    return (
        <div
            className='js-fmc-segmented-control fmc-segmented-control'
            ref={segmentedControlRef}
        >
            <section className='fmc-segmented-control__controls'>
                <span className='fmc-segmented-control__slider'>
                    <span className='fmc-segmented-control__slider-text'></span>
                </span>
                <div
                    role='tablist'
                    className='fmc-segmented-control__buttons'
                    aria-label='Features'
                >
                    {buttonComponents()}
                </div>
            </section>
            <section className='fmc-segmented-control__panels'>
                {panelComponents()}
            </section>
        </div>
    );
};
export default SegmentedControl;
