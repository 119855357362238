class FMUtility {
    static isMarkup(text: string) {
        if (text == null) {
            return false;
        }
        const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
        return htmlTagPattern.test(text);
    }
}

export default FMUtility;
