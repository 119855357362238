export const KEYBOARD_KEYS = {
    ESC: 'Escape',
    TAB: 'Tab',
    ENTER: 'Enter',
    END: 'End',
    HOME: 'Home',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
};
