import React from 'react';
import { CheckboxItems, CheckboxProps } from '../../types/checkbox.types';
import './../../main.scss';

const Checkbox = (props: CheckboxProps) => {
    const classes: string[] = [];
    const checkBoxClasses = () => {
        if (props.direction === 'standard') {
            classes.push('fmc-check');
        } else {
            classes.push('fmc-check fmc-check--horizontal');
        }
        return classes.join(' ');
    };

    const onClick = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>,
        index: number
    ) => {
        if (props.checkboxArray[index].onClick) {
            props.checkboxArray[index].preventDefault && e.preventDefault();
            props.checkboxArray[index].onClick(e);
        }
    };

    function checkboxComponent() {
        return props.checkboxArray.map(
            (checkbox: CheckboxItems, index: number) => {
                return (
                    <label key={index}>
                        <input
                            onClick={(e) => onClick(e, index)}
                            type='checkbox'
                            name={checkbox.name}
                            value={checkbox.value}
                            disabled={checkbox.disabled}
                            data-testid={checkbox.label}
                            aria-label={checkbox.ariaLabel}
                            checked={checkbox.checked}
                            {...(checkbox.focus ? { autoFocus: true } : {})}
                        />

                        <span>{checkbox.itemName}</span>
                    </label>
                );
            }
        );
    }
    return (
        <>
            {props.legend && props.checkboxArray.length > 0 && (
                <div>
                    <fieldset className={checkBoxClasses()}>
                        <legend> {props.legend} </legend>
                        {checkboxComponent()}
                    </fieldset>
                </div>
            )}
            {!props.legend && props.checkboxArray.length > 0 && (
                <div className={checkBoxClasses()}>{checkboxComponent()}</div>
            )}
        </>
    );
};

export default Checkbox;
