import React from 'react';
import { FMInputProps } from '../../types/fm-input.types';
import './fm-input.scss';
const FMInput = React.forwardRef(function Input(
    props: FMInputProps,
    ref: React.Ref<HTMLInputElement>
) {
    const inputClasses = () => {
        const classes: string[] = ['fm-input'];
        if (props.state) {
            classes.push(`fm-input--${props.state}`);
        }
        if (props.customClass) {
            classes.push(props.customClass);
        }
        return classes.join(' ').trim();
    };
    const supportTextClasses = () => {
        const classes: string[] = ['fm-input-support-text'];
        if (props.state) {
            classes.push(`fm-input-support-text--${props.state}`);
        }
        return classes.join(' ').trim();
    };
    function headerClasses() {
        return ['fm-input-header', `${props.disabled ? '--disabled' : ''}`]
            .join(' ')
            .trim();
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };
    return (
        <div className={'fm-input-container'}>
            <label htmlFor={props.name} className={headerClasses()}>
                {props.header}
            </label>
            <input
                id={props.id}
                value={props.value}
                className={inputClasses()}
                {...(props.disabled ? { disabled: true } : {})}
                {...(props.required ? { required: true } : {})}
                {...(props.minLength ? { minLength: props.minLength } : {})}
                {...(props.maxLength ? { maxLength: props.maxLength } : {})}
                {...(props.readOnly ? { readOnly: props.readOnly } : {})}
                data-testid={props.dataTestId}
                onChange={(e) => onChange(e)}
                onClick={props.onClick}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                onKeyDown={props.onKeyDown}
                onKeyUp={props.onKeyUp}
                placeholder={props.placeholder}
                type={props.type || 'text'}
                name={props.name}
                aria-label={props.ariaLabel}
                aria-labelledby={props.ariaLabelledBy}
                ref={ref}
                aria-describedby={props.ariaDescribedBy}
                aria-required={props.ariaRequired}
            />
            {props?.footer && (
                <div className={supportTextClasses()}>{props.footer}</div>
            )}
        </div>
    );
});
export default FMInput;
