import React, { ReactElement, ReactNode, useState } from 'react';
import { AccordionItemProps } from '../../types/accordion.types';
import './accordion-item.scss';

const AccordionItem = (props: AccordionItemProps) => {
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const onClick = () => {
        setExpanded(!isExpanded);
        props.onPanelClick(
            typeof props.header === 'string'
                ? props.header
                : (props.header as ReactElement)?.props?.children,
            !isExpanded
        );
    };

    const isMarkup = (text: string | ReactNode) => {
        if (typeof text === 'string')
            return text.trim().startsWith('<') && text.trim().endsWith('>');
        else return false;
    };

    return (
        <div
            key={`${props.header}-${props.index}`}
            className={[
                'fmc-accordion__panel',
                isExpanded
                    ? 'fmc-accordion--expanded fmc-accordion--active'
                    : '',
            ]
                .join(' ')
                .trim()}
        >
            <button
                type='button'
                className='fmc-accordion__button'
                id={`panel-${props.index}-button`}
                aria-controls={`panel-${props.index}-content`}
                aria-expanded={isExpanded}
                onClick={onClick}
            >
                {isMarkup(props.header) && (
                    <span
                        className='fmc-accordion__button-title'
                        dangerouslySetInnerHTML={{
                            __html: props.header.toString(),
                        }}
                    />
                )}
                {!isMarkup(props.header) && (
                    <span className='fmc-accordion__button-title'>
                        {props.header}
                    </span>
                )}
                <span className='fmc-accordion__button-expand fds-icon'></span>
            </button>
            <div className='fmc-accordion__body'>
                <div
                    className='fmc-accordion__content'
                    aria-labelledby={`panel-${props.index}-button`}
                    id={`panel-${props.index}-content`}
                    role='region'
                    aria-hidden='false'
                >
                    {isMarkup(props.body) && (
                        <div
                            className='fmc-type--body1 fmc-type--nodebody'
                            dangerouslySetInnerHTML={{
                                __html: props.body.toString(),
                            }}
                        />
                    )}
                    {!isMarkup(props.body) && (
                        <div className='fmc-type--body1'>{props.body}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default AccordionItem;
